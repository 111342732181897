@import "@Static/css/common";

.testimonialCardContainer {
    z-index: 2;
    margin: 0.5rem 1rem;
}
.testimonialSubContainer {
    background-color: #ffff;
    @include colorCombinationXYZ(
        (
            "bgc": (
                "key": 3,
                "color": (255, 255, 255)
            ),
        ),
        "schema2"
    );
    border-radius: 8px;
    border: 2px solid #e4e8f0;
    padding: 2rem;
    min-width: 100%;
    &.mediaFeedbackContainer {
        padding: 3px;
    }
}
.mediaContainer {
    border-radius: 8px 8px 0px 0px;
    display: flex;
    justify-content: center;
        height: 268px;
    // TODO: Replace
    background: linear-gradient(
        to right,
        getColorValue("1", (0, 0, 0)),
        getColorValue("2", (255, 255, 255)),
    );
    iframe, video {
        border-radius: 8px 8px 0px 0px;
        height: 100%;
    }
    img {
        width: 50%;
        height: 268px;
        object-fit: cover;
    }
}
.mediaOverlay {
    cursor: pointer;
    width: calc(100% - 2.3rem);
    height: calc(100% - 5.3rem);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    &.imageOverlay {
        svg {
            circle {
                fill: getColorValue("1", (0, 0, 0));
            }
        }
    }
}
.quote {
    position: absolute;
    opacity: 0.3;
    path {
        fill: getColorValue("1", (0, 0, 0));
    }
}
.profileSection {
    display: flex;
}
.mediaFeedbackContainer {
    .profileSection {
        padding: 0.5rem 1rem;
        height: auto;
    } 
}
.testimonialprofileImg {
    height: 3rem;
    width: 3rem;
    border-radius: 0.5rem;
}
.profileName {
    @include colorCombination1(("tc": ("color": (0, 0, 255))));
    margin-left: 1rem;
    font-size: 14px;
    font-weight: 650;
    overflow: hidden;
}
.testimonialCardTitle {
    font-size: 14px;
    padding: 50px 0px;
    min-height: 220px;
    white-space: pre-wrap;
    word-break: break-word;
    @include colorCombinationXYZ(
        (
            "tc": (
                "key": 2,
                "color": (0, 0, 0)
            ),
        ),
        "schema2"
    );
}
.readMore {
    @include colorCombination1(("tc": ("color": (0, 0, 255))));
    text-decoration: underline;
    cursor: pointer;
    transition: 0.2s ease-in-out all;
    &:hover {
        @include colorCombination1(("tc": ("color": (0, 0, 255), "opacity": 0.5)));
    }
}