@import "@Static/css/common";

.portfolioTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 1;
    padding-bottom: 15px;
    @include colorCombination1(
        (
            "tc": (
                "color": (
                    237,
                    72,
                    83,
                ),
            ),
            "boc": (
                "color": (
                    237,
                    72,
                    83,
                ),
            ),
        )
    );
    border-bottom: 1px solid;
    width: 50%;
    max-width: 90vw;
}

.portfolioHeading {
    margin: 30px 0px 50px;
}
.imageModalStyle {
    display: flex;
    width: 100%;
    // height: 80%;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    background-color: transparent;
    outline: none;
    /* padding-top: 40px; */
}
.arrowStyle {
    color: white;
    display: flex;
    width: 10%;
    position: absolute;
    border-radius: 50%;
    margin: 0 12px;
    justify-content: center;
    font-size: 50px;
    cursor: pointer;
    margin-bottom: 40px;
    background-color: rgba(0, 0, 0, 0.5);
}
.modalContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 10px solid transparent;
    flex-direction: column;
    img {
        height: 478px;
        background-color: #ccc;
    }
}

.textOn {
    font-size: 16px;
    text-align: center;
    text-overflow: ellipsis;
    margin: 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.portfolioHeading .galleryItem {
    padding: 5px 17px;
    border: 1px solid;
    cursor: pointer;
    @include colorCombination1(
        (
            "tc": (
                "color": (
                    237,
                    72,
                    83,
                ),
            ),
        )
    );
    transition: 0.1s ease all;
    &:not(.selectedItem):hover {
        @include colorCombinationXYZ(
            (
                "bgc": (
                    "key": 1,
                    "color": (
                        237,
                        72,
                        83,
                    ),
                    "opacity": 0.1,
                ),
            )
        );
    }
}

.portfolioHeading .selectedItem {
    padding: 5px 17px;
    color: #fff;
    @include colorCombination2(
        (
            "bgc": (
                "color": (
                    237,
                    72,
                    83,
                ),
            ),
        )
    );
}

.portfolioContainer {
    position: relative;
    text-align: left;
    padding: 20px;
    max-width: 1480px;
    margin: auto;

    button {
        background-color: transparent;
        margin: auto;
        transform: rotateZ(-90deg);
        border-radius: 50%;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2) !important;
    }
}

.portfolio {
    margin: 0 70px;
    position: relative;
    svg {
        g {
            path {
                fill: getColorValue("1");
            }
        }
    }
}

.galleryButton {
    position: absolute;
    cursor: pointer;
    top: 151px;
    background-color: #333c;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    line-height: 20px;
    border-radius: 100%;
    padding: 8px;
    transition: 0.1s ease-out all;
    &:hover {
        background-color: #333e;
    }
}

.leftButton {
    @extend .galleryButton;
    left: -75px;
}

.rightButton {
    @extend .galleryButton;
    right: -75px;
}

.portfolio .rec-arrow-right {
    margin-left: -20px;
}

.portfolio .rec-dot {
    display: none;
}

.portfolio .lgksPe:hover:enabled,
.portfolio .lgksPe:focus:enabled {
    background-color: transparent !important;
}

.portfolio .rec-arrow {
    border-radius: unset;
    border: none;
    color: transparent;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    width: 45px;
    height: 50px;
    min-width: 45px;
}
.portfolio .rec-arrow:hover {
    box-shadow: none !important;
}

.galleryPlaceholderContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin: auto;
}

.eventCardPlaceholder {
    align-items: flex-start;
    display: flex;
    border-radius: 4px;
    margin: 30px 30px 2rem;
    max-width: 95vw;
    justify-content: flex-start;
    position: relative;
    flex-direction: column;

    display: flex;
    justify-content: center;
    align-items: center;

    color: rgb(var(--template-block-text-host-theme, 34, 34, 34));
    cursor: pointer;
    counter-increment: previewCard;
    .cardImage {
        box-shadow: 0px 0px 15px #00000033;
        background-color: #f2f2f4;
        height: 300px;
        width: 350px;
        // aspect-ratio: 1;
        background-image: url("/static/images/default-image-placeholder.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;

        &::after {
            content: "Gallery Item " counter(previewCard);
            position: absolute;
            left: 0%;
            top: 0%;
            width: 100%;
            height: 100%;
            background-color: #333c;
            color: #fff;
            backdrop-filter: blur(4px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            letter-spacing: -0.4px;
        }
    }
    .caption {
        margin: 16px 0 -6px;
        font-weight: 600;
        font-size: 16px;
    }
}

.portfolioPlaceholder {
    margin: 50px;
    font-size: 30px;
    max-width: 1440px;
    font-weight: 600;
    margin: auto;
}

@media only screen and (max-width: 1070px) {
    .galleryPlaceholderContainer {
        flex-direction: column;
        justify-content: center;
    }
}

@media only screen and (max-width: 768px) {
    .portfolio {
        margin: 0px 20px 20px;
    }
    .portfolioTitle {
        font-size: 20px;
        width: 300px;
        max-width: 90vw;
        margin-left: 20px;
    }
    .portfolioHeading {
        text-align: center;
        margin: 27px 0;
    }
    .portfolioHeading .item {
        line-height: 30px;
    }
    .portfolio .rec-arrow-left {
        display: none;
    }
    .portfolio .rec-arrow-right {
        display: none;
    }

    .portfolio .rec-dot {
        display: block;
        outline: none;
        width: 7px;
        height: 7px;
        box-shadow: unset;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .portfolio .rec-dot_active {
        background-color: #ed4853;
        box-shadow: 0 0 1px 3px #ed4853;
    }

    .cJeRRR:hover,
    .cJeRRR:focus {
        box-shadow: 0 0 1px 3px #ed4853 !important;
    }

    .portfolioContainer {
        padding: 25px 0 0;
        button {
            background-color: #c4c4c4;
            margin: 5px;
        }
    }

    .portfolio .bkVSTc {
        margin: 15px 0px;
    }

    .lineBreak:before {
        content: "\A";
    }

    .portfolioPlaceholder {
        margin: auto;
        display: block;
    }
    .eventCardPlaceholder {
    }

    .textheadingtestimonail {
        text-align: center;
        margin-bottom: 20px;
        font-size: 24px;
    }

    .arrowStyle {
        width: 50px;
    }
    .modalContainer {
        img {
            height: unset;
        }
    }
}

.anchorGallery {
    position: absolute;
    transform: translateY(-120px);
}
