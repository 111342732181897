@import "@Static/css/common";

.teamSectionContainer {
    &::before {
        @include colorCombinationXYZ(
            (
                "bgc": (
                    "key": 1,
                    "color": (
                        237,
                        72,
                        83,
                    ),
                ),
            ),
            "schema1"
        );
        content: "";
        position: absolute;
        width: 100%;
        height: 174px;
    }
}

.teamAnchor {
    transform: translateY(-120px);
}

.teamSectionSubContainer {
    padding: 40px 20px;
    max-width: 1480px;
    margin: auto;
    position: relative;
}

.title {
    font-size: 1.5rem;
    position: relative;
    font-weight: 600;
    color: #fff;
}

.buttonGroup {
    display: flex;
    position: absolute;
    right: 0.5rem;
    top: 2.5rem;
}

.arrowStyle {
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    font-size: 2rem;
    height: 2rem;
    margin: 0rem 0.5rem;
    cursor: pointer;
    @include colorCombinationXYZ(
        (
            "bgc": (
                "key": 2,
                "color": (
                    237,
                    72,
                    83,
                ),
            ),
            "tc": (
                "key": 3,
                "color": (
                    255,
                    255,
                    255,
                ),
            ),
        ),
        "schema1"
    );
    transition: 0.2s ease-in-out all;
    &:hover {
        @include colorCombinationXYZ(
            (
                "bgc": (
                    "key": 2,
                    "color": (
                        237,
                        72,
                        83,
                    ),
                    "opacity": 0.75,
                ),
            ),
            "schema1"
        );
    }
}

@media only screen and (max-width: 768px) {
    .arrowStyle {
        transform: translateY(-35px) scale(0.8);
    }
}
