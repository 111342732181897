@import "@Static/css/common";

.metaData {
    @include colorCombination1(
        (
            "tc": (
                "color": (
                    0,
                    0,
                    0,
                ),
            ),
        ),
        "schema4"
    );
    font-size: 22px;
    text-align: center;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 339px;
    margin-left: 0;
}

.portfolioImgContainer {
    position: relative;
    cursor: pointer;
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: transparent;
        top: 0;
        left: 0;
    }
    img {
        height: 340px;
        width: 340px;
        margin: auto;
        background-color: #ccc;
    }
}

.video {
    position: relative;
    height: 340px;
    width: 340px;
    video {
        $aspect-ratio: 16/9;
        $responsive-width: 200px;
        $min-width: 100px;
        $max-width: 300px;

        @include aspectRatio(
            $responsive-width,
            $aspect-ratio,
            $min-width,
            $max-width
        );
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}

.portfolioCard {
    margin: 10px;
}

@media only screen and (max-width: 768px) {
    .metaData {
        font-size: 16px;
        width: auto;
        margin-bottom: 25px;
        height: 100%;
    }

    .portfolioImgContainer {
        img,
        .video {
            height: 300px !important;
            width: 300px !important;
            max-width: calc(100vw - 60px) !important;
            max-height: calc(100vw - 60px) !important;
        }
    }
}
