@import "@Static/css/common";

.aboutContainer {
    display: flex;
    max-width: 1480px;
    padding: 0 20px;
    margin: 59px auto 0;
    .imageContainer {
        margin: 0 20px;

        img {
            width: clamp(30vw, 30vw, 400px);
            box-shadow: -15px 10px getColorValue("1");
            padding-right: 10px;
        }
    }
}

.aboutContainer {
    .aboutData {
        @include colorCombinationXYZ(
            (
                "tc": (
                    "color": (
                        0,
                        0,
                        0,
                    ),
                    "key": 3,
                ),
            )
        );
        display: inline-block;
        width: 90%;
        text-align: left;
        overflow: hidden;
        .shortDesc {
            font-weight: 600;
            padding: 10px 0;
            word-wrap: break-word;
        }
    }
}

.aboutData {
    .aboutHeading {
        font-size: 50px;
        border-bottom: 1px solid;
        @include colorCombination1(
            (
                "tc": (
                    "color": (
                        237,
                        72,
                        83,
                    ),
                ),
                "boc": (
                    "color": (
                        237,
                        72,
                        83,
                    ),
                ),
            )
        );
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: auto;
    }
}

.desc {
    word-wrap: break-word;
    .readMore {
        @include colorCombination1(
            (
                "tc": (
                    "color": (
                        237,
                        72,
                        83,
                    ),
                ),
            )
        );
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}

.about {
    font-weight: 100;
    padding-right: 10px;
}

.name {
    font-weight: 600;
    word-wrap: break-word;
}

.previewshortbio {
    border: 0.5px solid;
    @include colorCombination1(
        (
            "boc": (
                "color": (
                    237,
                    72,
                    83,
                ),
            ),
        )
    );
    padding: 10px;
    text-align: center;
    border-radius: 2px;
    margin-top: 5px;
}

.previewProfile {
    width: 300px;
    height: 300px;
    font-size: 16px;
    color: #333;
    background-color: #ccc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.anchorAbout {
    position: absolute;
    transform: translateY(-120px);
}

@media only screen and (max-width: 768px) {
    .aboutContainer {
        margin-top: 18%;
        display: block;
        .imageContainer {
            display: block;
            float: unset;
            text-align: center;
            margin: 0 auto;

            img {
                width: unset;
                height: unset;
                padding-right: 0px;
                max-width: 90%;
                margin: auto;
                background-color: lightgrey;
                width: 200px;
                height: 200px;
            }
        }
    }

    .aboutContainer {
        .aboutData {
            width: 100%;
            padding: 20px 0;
            text-align: left;

            .shortDesc {
                text-align: left;
            }
            .aboutHeading {
                font-size: 25px;
                text-align: left;
            }
        }
    }
    .previewProfile {
        margin: auto;
        width: 250px;
        height: 250px;
    }
}
