@import "@Static/css/common";

.teamMemberCardContainer {
    background: #aaa center no-repeat;
    background-size: cover;
    max-width: 17rem;
    height: 24.5rem;
    margin: 1rem auto;
    border-radius: 1rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    user-select: none;
}

.socialIconsContainer {
    display: flex;
    justify-content: flex-end;
}

.socialIcon {
    background-color: rgba(0, 0, 0, 0.75);
    width: 1.7rem;
    height: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 6px 6px 0 0;
    color: white;
    font-size: 1.3rem;
}

.detailsContainer {
    height: 50%;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 50.22%);
    color: white;
    border-radius: inherit;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    line-height: 1.1;
}

.name {
    font-weight: 700;
    font-size: 1.1rem;
    margin-bottom: 4px;
}

.heading {
    font-size: 0.7rem;
    font-weight: 600;
    height: 27.2px;
    overflow-wrap: break-word;
    word-break: keep-all;
    @extend .plainScrollbar;
}

.horizontalBar {
    height: 2px;
    width: 2rem;
    margin: 10px 0;
    background-color: rgb(var(--primary-color-rgb-host-theme, 255, 255, 255));
}

.description {
    font-size: 0.7rem;
    height: 2.4rem;
    overflow-y: auto;
    overflow-wrap: break-word;
    word-break: keep-all;
    @extend .themedScrollbar;
}