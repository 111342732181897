@import "@Static/css/common";

.testimonialtemplateContainer {
    margin-bottom: 30px;
}

.testimonialtemplateSubContainer {
    height: 154px;
    padding: 40px 20px;
    max-width: 1480px;
    margin: auto;
    &::before {
        @include colorCombination2(
            (
                "bgc": (
                    "color": (
                        237,
                        72,
                        83,
                    ),
                ),
            )
        );
        content: "";
        position: absolute;
        width: 100%;
        left: 0;
        height: 174px;
        margin-top: -20px;
        z-index: 0;
    }
}

.testimonialTitle {
    font-size: 1.5rem;
    position: relative;
    font-weight: 600;
    color: #fff;
}
.horizontalBar {
    width: 10rem;
    height: 2px;
    background-color: #000;
}
.controls {
    display: flex;
    background-color: transparent !important;
    position: absolute;
    right: 0.5rem;
    top: -3rem;
}
.arrowStyle {
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    height: 2rem;
    font-size: 2rem;
    margin: 0rem 0.5rem;
    cursor: pointer;
    transition: 0.2s ease-in-out all;
    @include colorCombinationXYZ(
        (
            "bgc": (
                "key": 2,
                "color": (
                    237,
                    72,
                    83,
                ),
            ),
            "tc": (
                "key": 3,
                "color": (
                    255,
                    255,
                    255,
                ),
            ),
        ),
        "schema1"
    );
    transition: 0.2s ease-in-out all;
    &:hover {
        @include colorCombinationXYZ(
            (
                "bgc": (
                    "key": 2,
                    "color": (
                        237,
                        72,
                        83,
                    ),
                    "opacity": 0.75,
                ),
            ),
            "schema1"
        );
    }
}
.testimonialTemplate {
    display: block;
    position: relative;
    margin: auto;
    max-width: 1400px;
    .carousalItems {
        margin-top: -50px;
        & > div {
            z-index: 3;
        }
    }
}
.dotMatrix {
    circle {
        fill: getColorValue("1", (0, 0, 0));
    }
    position: relative;
    left: 96%;
    top: -2.5rem;
    z-index: 1;
}
.eventCardPlaceholder {
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 0px 15px #00000033;
    width: 400px;
    align-items: flex-start;
    display: flex;
    border-radius: 4px;
    margin-left: 30px;
    padding-bottom: 2%;
    justify-content: flex-start;
    position: relative;
    flex-direction: column;
}

.textheadingtestimonail {
    margin: 20px 50px;
    font-size: 30px;
}

.anchorTestimonial {
    position: absolute;
    transform: translateY(-120px);
}

@media only screen and (max-width: 1440px) {
    .dotMatrix {
        display: none;
    }
}

@media only screen and (max-width: 1299px) {
    .testimonialtemplateContainer {
        padding: 20px 0px;
    }
}

@media only screen and (max-width: 768px) {
    .testimonialtemplateSubContainer {
        margin-bottom: 2rem;
    }
    .testimonialPlaceholder {
        margin: auto;
        text-align: center;
        display: block;
    }
}
